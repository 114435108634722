<!--
 * @Description: 视频管理 停车场视频监控 历史视频监控 historyVideoMonitor
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-19 11:47:57
 * @LastEditors: zhoucheng
-->
<template>
  <div class=''
       style="height: 100%; width: 100%">
    <el-row style="height: 100%; width: 100%">
      <el-col :span="8"
              v-for="(item, index) in urlList"
              :key="index"
              @click.native="handleClick(index)"
              style="height: 100%">
        <video :ref="index"
               width="100%"
               :id="index"></video>
      </el-col>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import flvjs from 'flv.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      urlList: ['', '', '', '']
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    handleClick (idx) {
      this.$realTimeVideoMonitor.getVideoUrl({}).then(res => {
        this.createFlvPlayer(this.$refs[idx][0], res)
      })
    },
    //  新建实例
    createFlvPlayer (element, flvUrl) {
      if (flvjs.isSupported()) {
        // var videoElementOne = document.getElementById('videoElementOne');
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: flvUrl
        });
        flvPlayer.attachMediaElement(element);
        flvPlayer.load();
        flvPlayer.play();
      }
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
</style>